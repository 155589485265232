
import { Component, Vue, Watch } from "vue-property-decorator";
import { TaskInterface } from "@/interfaces/task";
import { VehicleInterface } from "@/interfaces/vehicle";
import Vehicle = VehicleInterface.Vehicle;
import ModalStock from "@/components/_modal/ModalStock.vue";
import ModalDownload from "@/components/_modal/ModalDownload.vue";
import ModalProcess from "@/components/_modal/ModalProcess.vue";
import FilterEcu from "@/components/_inner/FilterEcu.vue";
import NoteError from "@/components/_tools/NoteError.vue";
import Patches from "@/components/_inner/Patches.vue";
import Dtc from "@/components/_inner/Dtc.vue";
import storage from "@/storage";
import api from "@/api";
import getCurrencySymbol from "@/additionally/getCurrencySymbol";
import getLangIdObject from "@/additionally/getLangIdObject";
import ResultPanel from "@/components/_inner/ResultPanel.vue";
import ModalMmcError from "@/components/_modal/ModalMmcError.vue";

type Payback = {
  done: Function;
};

@Component<MmcOrderPage> ({
  components: {
    ModalMmcError,
    ModalStock,
    ModalDownload,
    ModalProcess,
    FilterEcu,
    NoteError,
    Patches,
    Dtc,
    ResultPanel,
  },
  metaInfo() {
    return {
      title: this.$tc("caption"),
    };
  },
})
export default class MmcOrderPage extends Vue {
  showPayModal = false;
  showDownloadModal = false;
  showProcessModal = false;
  error = false;
  startFilterParams = {};
  task: TaskInterface.Task | null = null;
  fileId = 0;
  promoCode = "";
  currencyId = 1;
  dtcBillingDescription = "";
  dtcBillingNote = "";
  promoDescription = "";
  downloadToken = "";
  filterEmpty = true;
  isEmptyForm = true;
  timerstop = false;
  invalidUrl = false;
  readOnly = true;
  timeoutError = false
  isShowProcessPayment = false;
  isProdamusError = false;
  isProdamusSuccess = false;
  isShowModalError = false;

  @Watch("$i18n.locale")
  onWatchLocales() {
    this.addTitle();
    this.getBillingInfo();
  }

  getDescriptionData() {
    return this.task?.subscription;
  }

  addTitle() {
    ////document.title = this.$tc('caption')
  }

  checkEmptyForm(task: any) {
    function isEmptyForm(task: any) {
      if (
        task?.patches.find((item: any) => item.isSelected) ||
        task?.taskDtcCurrent.items.find((item: any) => item.isSel)
      ) {
        return false;
      }

      return true;
    }

    this.isEmptyForm = isEmptyForm(task);
  }

  getBillingInfo() {
    if (this.task) {
      const billingDescriptions: TaskInterface.BillingDescriptions = getLangIdObject<TaskInterface.BillingDescriptions>(this.task.billingDescriptions);

      this.dtcBillingDescription = billingDescriptions.tdcBillingDescription;
      this.dtcBillingNote = billingDescriptions.tdcBillingNote;
      this.promoDescription = billingDescriptions.promoDescription;
    }
  }

  getCurrencyId() {
    const id = storage.user?.currencyId;
    if (id) this.currencyId = id;
  }

  async createTask(data: { completed: boolean; vehicle: Vehicle }) {
    if (this.invalidUrl) return
    this.error = false
    this.filterEmpty = !data.completed
    if (!data.completed) return
    if (this.fileId > 0)
      this.showProcessModal = true
    const task = await api.task.edit({
      vehicle: data.vehicle,
      fileId: this.fileId,
    });

    const timer = setInterval(() => {
      if (this.timerstop)
      {
        this.showProcessModal = false
        this.timerstop = false
      } else {
        clearInterval(timer)
      }
    }, 1000)

    this.timerstop = true

    if (!task) return (this.error = true);
    if (this.fileId > 0) {
      //console.log('task.vehicle', task.vehicle)
      // @ts-expect-error
      // eslint-disable-next-line
      this.$refs.myFilter.setAnotherEcu(task.vehicle)
    }
    this.refreshTask(task);
    await this.calcPrice(task);
  }

  async calcPrice(task = this.task) {
    this.checkEmptyForm(task);
    if (!task) return;
    const newTask = await api.task.calcPriceV2({
      ...task,
      fileId: this.fileId,
      promoCode: this.promoCode,
      currencyId: this.currencyId,
    });
    this.refreshTask(newTask);
  }

  refreshTask(task: TaskInterface.Task | null) {
    if (!task) return (this.error = true);
    this.task = task;
    storage.lastTaskVehicle = task ? task.vehicle : null;
    this.getBillingInfo();
  }

  refreshPromoCode(code: string) {
    this.promoCode = code;
    this.calcPrice();
  }

  async eventSaveAndOpenModal() {
    const saved = await this.saveTask();
    if (saved) return this.openPayModal();
    alert("Error save task");
  }

  async saveTask(
    payback: Payback = {
      done: () => {
        // console.log();
      },
    }
  ): Promise<boolean> {
    if (!this.task) return false;
    const task = await api.task.save({
      ...this.task,
      fileId: this.fileId,
      promoCode: this.promoCode,
    });
    this.refreshTask(task);

    this.$nextTick(() => {
      payback.done();
    });

    return !!task;
  }

  openPayModal() {
    this.showPayModal = true;
  }

  closePayModal() {
    //// @ts-expect-error
    //// eslint-disable-next-line
    //Robokassa.ClosePaymentForm();
    this.showPayModal = false;
    this.closeProcessPayment()
    this.openDownloadModal();
  }

  async openDownloadModal() {
    if (!this.task) return;
    const status = await api.payment.getTaskStatus(this.task.taskId);
    if (status !== "Paid") return;
    const token: string | null = await api.task.getDownloadLink(
      this.task.taskId
    );
    if (!token) return;
    this.downloadToken = token;
    this.showDownloadModal = true;
  }

  closeDownloadModal() {
    this.showDownloadModal = false;
    this.$router.push({
      name: "History",
      params: { lang: this.$route.params.lang },
    });
  }

  async loginByApiKey (apiKey: string) {
    try {
      const isAuth = await api.mmc.login(apiKey, 60000)
      return isAuth
    } catch (error) {
      if (error.name === 'AbortError') {
        this.timeoutError = true
      }
    }
  }

  async getStartState () {
    const { file, type, brand, model, engine, ecu, apiKey } = this.$route.query
    if (!apiKey) return this.invalidUrl = true
    if (typeof apiKey === 'string') {
      this.showProcessModal = true
      const isAuth = await this.loginByApiKey(apiKey)
      isAuth ? await api.authorize.getUser() : this.invalidUrl = true
      this.showProcessModal = false
    }
    this.invalidUrl = !(file && type && brand && model && engine && ecu)
    if (this.invalidUrl) return
    this.fileId = +file || 0
    this.startFilterParams = {
      typeId: +type || 0,
      brandId: +brand || 0,
      modelId: +model || 0,
      engineId: +engine || 0,
      ecuId: +ecu || 0,
    }
  }

  showProcessPayment () {
    this.isShowProcessPayment = true
  }

  closeProcessPayment () {
    this.isShowProcessPayment = false
  }

  showModalError() {
    this.isShowModalError = true
  }

  closeModalError() {
    this.isShowModalError = false
  }

  eventError () {
    this.showModalError()
    this.closePayModal()
    this.closeProcessPayment()
  }

  get total(): number {
    return this.price - this.discount;
  }

  get price(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalPriceSum) sum += this.task.patchesTotalPriceSum;
    if (this.task.taskDtcCurrentTotalPriceSum)
      sum += this.task.taskDtcCurrentTotalPriceSum;
    return sum;
  }

  get discount(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalDiscountSum)
      sum += this.task.patchesTotalDiscountSum;
    if (this.task.taskDtcCurrentTotalDiscountSum)
      sum += this.task.taskDtcCurrentTotalDiscountSum;
    return sum;
  }

  get currency(): string {
    if (!this.task) return "";
    return getCurrencySymbol(this.task.currencyId);
  }

  created() {
    this.addTitle();
    this.getBillingInfo();
  }

  mounted() {
    this.getStartState()
    this.getCurrencyId();
    this.checkEmptyForm(this.task);
  }
}

